<template>
    <div class="w-full flex justify-center items-start mb-16">
        <Wrapper class="max-w-lg">
            <div class="w-11/12 md:w-11/12 m-auto">
                <AuthenticatedCode label="Introduce el código de autorización enviado al correo electrónico sev@live.com" />
            </div>
        </Wrapper>
    </div>
</template>

<script>
import AuthenticatedCode from '../../components/UI/AuthenticatedCode.vue';
export default {
    components: {
        AuthenticatedCode
    },
    data() {
        return {

        };
    },
    created() {
    },
    components: {
    },
    methods: {

    },
    computed: {

    }
}
</script>